































































import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Experiment from '@/models/experiment/Experiment';
import UserService from '@/services/UserService';
import UserInfo from '@/models/users/UserInfo';
import ExperimentService from '@/services/ExperimentService';
import ImportExperimentParticipantsModal from '@/components/modals/Experiment/ImportExperimentParticipantsModal.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { ImportExperimentParticipantsModal },
})
export default class ParticipantsList extends mixins(Toasts) {
  protected pageSize = 10;
  protected currentPageNo = 1;
  @Prop({ required: true })
  protected experiment!: Experiment;
  protected participants: UserInfo[] = [];

  mounted(): void {
    this.getAllParticipantsForExperiment();
  }

  protected getAllParticipantsForExperiment(): void {
    if (this.experiment.participants.length <= 0) {
      this.participants = [];
      return;
    }
    UserService.getUserInfoByIdsArray(this.experiment.participants)
      .then((res) => {
        this.participants = res;
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load participants. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected showUploadModal(): void {
    this.$bvModal.show('import-experiment-participants-modal');
  }

  protected removeParticipant(participant: UserInfo) {
    if (confirm('Do you really want to remove user ' + participant.uid + ' from the experiment?')) {
      if (this.experiment.participants.includes(participant.uid)) {
        const index = this.experiment.participants.indexOf(participant.uid, 0);
        this.experiment.participants.splice(index, 1);
        this.saveExperiment();
        this.getAllParticipantsForExperiment();
      }
    }
  }

  protected saveExperiment(): void {
    this.setLoading(true);
    ExperimentService.updateExperimentParticipants(this.experiment)
      .then(() => {
        this.setLoading(false);
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to save', 'Could not add experiment. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected navigate(name: string): void {
    this.$router.push(name);
  }

  protected participantFields = [
    {
      key: 'uid',
      label: 'Username',
      sortable: true,
    },
    {
      key: 'firstName',
      label: 'Firstname',
      sortable: true,
    },
    {
      key: 'lastName',
      label: 'Lastname',
      sortable: true,
    },
    {
      key: 'matriculationNumber',
      label: 'Matriculation Nr.',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      sortable: false,
    },
  ];
}













import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Experiment from '@/models/experiment/Experiment';
import ExperimentService from '@/services/ExperimentService';
import EditExperiment from '@/views/experiment/EditExperiment.vue';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import TaskList from '@/components/experiment/TaskList.vue';
import ParticipantsList from '@/components/experiment/ParticipantsList.vue';

@Component({
  components: { ParticipantsList, EditExperiment, TaskList },
})
export default class ExperimentAdminOverview extends mixins(Toasts) {
  protected experiment: Experiment = new Experiment('', '', '', '', [], []);
  protected id = -1;
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.setLoading(true);
      this.id = Number.parseInt(this.$route.params.id);
      if (Number.isNaN(this.id)) {
        this.$router.replace({ path: '/experiment/admin' });
        return;
      }
      ExperimentService.getExperimentById(this.id)
        .then((experiment) => {
          this.setLoading(false);
          this.experiment = experiment;
          this.breadcrumbs = [
            { text: 'Home', to: '/' },
            { text: 'Experiment Administration', to: '/experiment/admin' },
            { text: this.experiment.name, active: true },
          ];
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast(
              'Failed to load',
              'Could not load experiment. ' + backendError.response.data.message,
              'danger'
            );
          }
        });
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}

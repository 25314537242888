




































import { Component, Prop } from 'vue-property-decorator';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import ExperimentService from '@/services/ExperimentService';

@Component({})
export default class ImportExperimentParticipantsModal extends mixins(Toasts) {
  protected title = 'Upload Participant List';
  protected file!: File;

  @Prop({ required: true })
  protected experimentid!: number;

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  protected submitFile(): void {
    ExperimentService.importParticipants(this.file, this.experimentid)
      .then(() => {
        this.showToast('Participants imported', 'Participants have been imported.', 'success');
        this.$router.go(0);
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to import',
            'Could not import participants. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected handleFileUpload(event): void {
    this.file = event.target.files[0];
  }
}
